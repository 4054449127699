<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ infoUsser.user }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="picture" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ infoUsser.letter }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("SETTINGS.USER.MAIN.TITLE") }}
          <small class="text-muted font-size-sm ml-2">
            # {{ $t("SETTINGS.USER.MAIN.MESSAGES") }}
          </small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            {{ infoUsser.user }} {{ infoUsser.lastname }}
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ infoUsser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t("SETTINGS.USER.MAIN.SING_OUT") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link
            to="/settings/profile"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md text-success">
                    <font-awesome-icon icon="user" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("SETTINGS.USER.MAIN.PROFILE") }}
                </div>
                <div class="text-muted">
                  {{ $t("SETTINGS.USER.MAIN.DES_MAIN") }}
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/settings/company"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
            v-if="isVisibleCompany"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md text-warning">
                    <font-awesome-icon icon="building" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("SETTINGS.USER.MAIN.BUSSINESS") }}
                </div>
                <div class="text-muted">
                  {{ $t("SETTINGS.USER.MAIN.DES_MAIN") }}
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/settings/teams"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
            v-show="isVisibleTeam"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md text-primary">
                    <font-awesome-icon icon="users" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("SETTINGS.USER.MAIN.TEAM") }}
                </div>
                <div class="text-muted">
                  {{ $t("SETTINGS.USER.MAIN.DES_MAIN") }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";
export default {
  name: "KTQuickUser",
  data() {
    return {
      user: null,
      letter: null,
      email: null,
      isVisibleCompany: true,
      isVisibleTeam: true,
      homeStay: 1,
      homeTuition: 4,
      accommodationCompany: 2,
      SCOPE_GENERAL: "general",
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    const { name, email } = this.$store.getters.currentUser;
    this.user = name;
    this.email = email;
    this.letter = name.charAt(0);
    this.validateShowTeam();
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    validateShowTeam() {
      const scopeGeneral = this.currentUser.user.scopes?.indexOf(
        this.SCOPE_GENERAL
      );
      if (this.company && this.company.companyTypeId === this.homeTuition) {
        this.isVisibleTeam = false;
      } else if (
        this.company &&
        this.company.companyTypeId === this.accommodationCompany
      ) {
        const resultFilter = this.accommodations?.filter(item => {
          return item.campusId === this.campus.campusId;
        });
        if (resultFilter.length > 0) {
          if (resultFilter[0].accommodationTypeId === this.homeStay) {
            this.isVisibleTeam = false;
          } else {
            if (scopeGeneral !== -1) {
              this.isVisibleTeam = true;
            }
          }
        }
      } else {
        if (scopeGeneral !== -1) {
          this.isVisibleTeam = true;
        }
        if (this.currentUser.user.companyId) {
          this.isVisibleCompany = true;
        } else {
          this.isVisibleCompany = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["company", "campus", "accommodations", "currentUser"]),
    picture() {
      const { user } = this.$store.getters.currentUser;
      if (user.photo !== null && typeof user.photo !== "undefined") {
        return user.photo.url + "?w=100&h=100&fit=crop";
      } else {
        return `${process.env.VUE_APP_CDN_PATH}images/default/blank.png`;
      }
    },
    infoUsser() {
      const { user } = this.$store.getters.currentUser;
      return {
        user: user.name,
        lastname: user.lastname,
        letter: user.name.charAt(0),
        email: user.email,
      };
    },
  },
  watch: {
    campus() {
      this.validateShowTeam();
    },
  },
};
</script>
