<template>
  <ul class="menu-nav">
    <template v-for="item in general_menu_filtered">
      <router-link
        v-if="typeof item.children === 'undefined'"
        :key="item.id"
        :to="`/${item.path}`"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-icon">
              <font-awesome-icon :icon="item.icon" />
            </span>
            <span class="menu-text">{{ item.name }}</span>
          </a>
        </li>
      </router-link>
      <li
        v-else
        :key="item.id"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren(`/${item.path}/${item.path}`),
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="menu-icon">
            <font-awesome-icon :icon="item.icon" />
          </span>
          <span class="menu-text">{{ item.name }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ item.name }}</span>
              </span>
            </li>

            <router-link
              v-for="children in item.children"
              :key="children.id"
              :to="`/${item.path}/${children.path}`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ children.name }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </template>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]"
    >
      <a
        href="https://partnerhelp.ynsitu.com/portal/en/home"
        class="menu-link"
        target="_blank"
      >
        <span class="menu-icon">
          <font-awesome-icon icon="question-circle" />
        </span>
        <span class="menu-text">
          {{ $t("GENERAL.MENU.HELP") }}
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export const CAMPUS = 1;
export const LODGING = 2;
export const HOMESTAY = 3;
export const HOMETUITION = 4;

export default {
  name: "KTMenu",
  data: function () {
    return {
      general_menu: [],
      HOME: "home",
      SUPERADMIN: 1,
      menuList: [
        {
          name: this.$t("GENERAL.MENU.HOME"),
          path: "home",
          icon: "home",
          meta: {
            rol: "",
            companyTypeId: [CAMPUS, LODGING, HOMESTAY, HOMETUITION],
            internal: true,
            external: true,
          },
        },
        {
          name: this.$t("GENERAL.MENU.CAMPUS"),
          path: "campus",
          icon: "laptop-house",
          meta: {
            rol: "general",
            companyTypeId: [CAMPUS],
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.HOMETUITION_PROFILE"),
          path: "campus",
          icon: "laptop-house",
          meta: {
            rol: "general",
            companyTypeId: [HOMETUITION],
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.LODGINGS"),
          path: "lodging",
          icon: "bed",
          meta: {
            rol: "general",
            companyTypeId: [CAMPUS],
            campusrequired: true,
            onlyCompanyAccess: true,
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.LODGINGS"),
          path: "lodging",
          icon: "bed",
          meta: {
            rol: "general",
            companyTypeId: [LODGING, HOMESTAY, HOMETUITION],
            onlyCompanyAccess: true,
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.LODGINGS"),
          path: "lodging",
          icon: "bed",
          meta: {
            rol: "general",
            companyTypeId: [CAMPUS],
            campusrequired: true,
            onlyCampusLodgingAccess: true,
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.LODGING"),
          path: "lodging/new",
          icon: "bed",
          meta: {
            rol: "general",
            companyTypeId: [LODGING, HOMESTAY],
            changepath: "individual_lodging",
            onlyCampusLodgingAccess: true,
            ifAccommodationExistSetEdit: true,
          },
        },
        {
          name: this.$t("GENERAL.MENU.APPLICATIONS"),
          path: "applications",
          icon: "box",
          meta: {
            rol: "reservation",
            campusrequired: true,
            internal: true,
            external: true,
          },
        },
        {
          name: this.$t("GENERAL.MENU.COURSES"),
          path: "products/course",
          icon: "book",
          meta: {
            rol: "products",
            campusrequired: true,
            companyTypeId: [CAMPUS, HOMETUITION],
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.ROOMS"),
          path: "products/room",
          icon: "suitcase-rolling",
          meta: {
            rol: "products",
            campusrequired: true,
            companyTypeId: [LODGING],
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.SERVICES"),
          path: "products/service",
          icon: "parking",
          meta: {
            rol: "products",
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.PROMOTIONS"),
          path: "promotions",
          icon: "bookmark",
          meta: {
            rol: "products",
            campusrequired: true,
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.PACKAGES"),
          path: "packages",
          icon: "boxes",
          meta: {
            companyTypeId: [CAMPUS],
            rol: "products",
            campusrequired: true,
            internal: true,
            external: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.REVIEWS"),
          path: "configure/reviews",
          icon: "comment-alt",
          meta: {
            rol: "general",
            campusrequired: true,
            internal: true,
            external: true,
          },
        },
        {
          name: this.$t("GENERAL.MENU.MESSAGESCENTER"),
          path: "messages",
          icon: "envelope",
          meta: {
            rol: "general",
            campusrequired: true,
            internal: true,
            external: true,
          },
        },
        {
          name: this.$t("GENERAL.MENU.PRICING"),
          path: "configure",
          icon: "money-check-alt",
          meta: {
            rol: "general",
            campusrequired: true,
            internal: true,
            external: false,
          },
          children: [
            {
              name: this.$t("GENERAL.MENU.PRICING_TEMPLATES"),
              path: "pricing",
            },
            {
              name: this.$t("GENERAL.MENU.FEES"),
              path: "fees",
            },
            {
              name: this.$t("GENERAL.MENU.MARKETS"),
              path: "markets",
            },
          ],
        },
        {
          name: this.$t("GENERAL.MENU.CALENDARS"),
          path: "configure",
          icon: "calendar-alt",
          meta: {
            rol: "general",
            campusrequired: true,
            internal: true,
            external: false,
          },
          children: [
            {
              name: this.$t("GENERAL.MENU.TIMETABLES"),
              path: "timetables",
            },
            {
              name: this.$t("GENERAL.MENU.CALENDAR"),
              path: "calendar",
            },
          ],
        },
        {
          name: "Company",
          path: "admin/company",
          icon: "building",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: "Product",
          path: "admin/product",
          icon: "book",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: "Reviews",
          path: "admin/review",
          icon: "comment-alt",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: "Log",
          path: "admin/log",
          icon: "suitcase-rolling",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: this.$t("GENERAL.MENU.COUPONS"),
          path: "admin/coupons",
          icon: "ticket-alt",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: "Feature Product",
          path: "admin/feature-product",
          icon: "star",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
        {
          name: "Application",
          path: "admin/application",
          icon: "box",
          meta: {
            superadmin: true,
            campusrequired: false,
          },
        },
      ],
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    itemsMenu() {
      this.menuList.forEach(item => {
        let item_filtered = item;
        if (this.company && Object.keys(this.company).length > 0) {
          const { companyTypeId } = this.company;

          if (item_filtered.children) {
            let children = item_filtered.children.filter(child => {
              if (child.companyTypeId) {
                if (child.companyTypeId.includes(companyTypeId)) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
            item_filtered.children = children;
          }

          if (item_filtered.meta) {
            let deteleelementmenu = false;
            if (item_filtered.meta.campusrequired) {
              if (!(this.campus && this.campus.campusId)) {
                //Right now, the user didn't create campus. This element can't be visible.
                deteleelementmenu = true;
              }
            }
            //we check ig the user is a company level acces, or a campus|lodging lecel access
            if (item_filtered.meta.onlyCampusLodgingAccess) {
              if (this.currentUser.user.companyId) {
                deteleelementmenu = true;
              }
            }
            if (item_filtered.meta.onlyCompanyAccess) {
              if (this.currentUser.user.campusId) {
                deteleelementmenu = true;
              }
            }
            if (item_filtered.meta.ifAccommodationExistSetEdit) {
              //We need to check if in this case exists accommodation. In that case, we need to change the path.
              if (this.accommodations.length > 0) {
                item_filtered.path =
                  "lodging/edit/" + this.accommodations[0].accommodationId;
              }
            }
            if (Number(this.currentUser.roleId) !== Number(this.SUPERADMIN)) {
              if (parseInt(this.company.origin_price) === 1) {
                if (item_filtered.meta.external === false) {
                  deteleelementmenu = true;
                }
              }
            }
            if (!deteleelementmenu) {
              if (item_filtered.meta.companyTypeId) {
                if (item_filtered.meta.companyTypeId.includes(companyTypeId)) {
                  this.general_menu.push(item_filtered);
                }
              } else {
                this.general_menu.push(item_filtered);
              }
            }
          }
        } else {
          if (
            item.path === this.HOME ||
            (item.meta.superadmin && item.meta.superadmin === true)
          ) {
            this.general_menu.push(item_filtered);
          }
        }
      });
    },
  },
  created() {
    this.itemsMenu();
  },
  computed: {
    ...mapGetters([
      "company",
      "campus",
      "accommodations",
      "accommodation",
      "currentUser",
    ]),
    general_menu_filtered() {
      let general_menu_filtered = this.general_menu.filter(to => {
        if (
          typeof to.meta !== "undefined" &&
          typeof to.meta.rol !== "undefined" &&
          to.meta.rol !== ""
        ) {
          if (typeof this.currentUser.user.user_roles !== "undefined") {
            if (
              Number(this.currentUser.roleId) === Number(this.SUPERADMIN) &&
              !this.currentUser.user.companyId &&
              !this.currentUser.user.campusId
            ) {
              return false;
            }
            const permisos = this.currentUser.user.user_roles.filter(rol => {
              if (rol.user_role_type.scope === to.meta.rol) return true;
              return false;
            });
            if (!permisos.length) {
              return false;
            }
          }
          return true;
        } else {
          if (to.meta.superadmin && to.meta.superadmin === true) {
            if (
              Number(this.currentUser.roleId) === Number(this.SUPERADMIN) &&
              !this.currentUser.user.companyId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
        return true;
      });
      return general_menu_filtered;
    },
  },
};
</script>
