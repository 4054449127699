<template>
  <div>
    <v-overlay :value="isReadyLoader">
      <v-progress-circular indeterminate size="128" class="pa-5">
        <span class="text-caption text-center ma-1">{{
          messages[Math.round(Math.random() * 7)]
        }}</span>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      messages: [
        "Loading",
        "Sheep entering the pen",
        "Filling the coffee cup",
        "Gathering the information",
        "Gathering unicorns",
        "Stroking dogs",
        "Gladdening lives",
        "Feed the unicorn",
      ],
    };
  },
  computed: {
    ...mapGetters(["isReadyLoader"]),
  },
};
</script>
