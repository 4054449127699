<template>
  <div></div>
</template>

<script>
import {
  required,
  email,
  max,
  min,
  numeric,
  max_value,
  min_value,
  regex,
} from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  validate(value, { max }) {
    return value.length <= max;
  },
  params: ["max"],
  message: "{_field_} may not be greater than {max} characters",
});

extend("regex_number_commas", {
  ...regex,
  message: "{_field_} must be list of numbers sepparate by commas",
});

extend("time", {
  ...regex,
  message: "{_field_} must has time format like HH:MM",
});

extend("min", {
  ...min,
  validate(value, { min }) {
    return value.length >= min;
  },
  params: ["min"],
  message: "{_field_} must have minimum {min} characters",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

extend("numeric", {
  ...numeric,
  message: "The {_field_} field may only contain numeric characters",
});

extend("max_value", {
  ...max_value,
  message: "The {_field_} cannot exceed {max} or less",
});

extend("max_value_percent", {
  ...max_value,
  message: "The {_field_} cannot exceed {max}%",
});

extend("min_value", {
  ...min_value,
  message: "The {_field_} must be minimal {min} or more",
});

extend("time_lower_than", {
  message: "The {_field_} must be lower than end time",
  validate: (start, end) => {
    if (end[0] === "" || typeof end[0] === "undefined") return true;
    else return start <= end;
  },
});

extend("time_greater_than", {
  message: "The {_field_} must be greater than start time",
  validate: (end, start) => {
    if (start[0] === "" || typeof start[0] === "undefined") return true;
    else return start <= end;
  },
});

extend("time_greater_than_previous", {
  message: "The {_field_} must be greater than previous end time",
  validate: (end, start) => {
    if (start[0] === "" || typeof start[0] === "undefined") return true;
    else return start <= end;
  },
});
export default {};
</script>
