var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.general_menu_filtered),function(item){return [(typeof item.children === 'undefined')?_c('router-link',{key:item.id,attrs:{"to":`/${item.path}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))])])])]}}],null,true)}):_c('li',{key:item.id,staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren(`/${item.path}/${item.path}`),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))])])]),_vm._l((item.children),function(children){return _c('router-link',{key:children.id,attrs:{"to":`/${item.path}/${children.path}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(children.name))])])])]}}],null,true)})})],2)])])]}),_c('li',{staticClass:"menu-item",class:[
      _vm.isActive && 'menu-item-active',
      _vm.isExactActive && 'menu-item-active',
    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"https://partnerhelp.ynsitu.com/portal/en/home","target":"_blank"}},[_c('span',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.MENU.HELP"))+" ")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }