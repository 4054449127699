<template>
  <div class="Notifications">
    <v-badge :content="count" :value="count" overlap @click="dialog = true">
      <v-icon @click="dialog = true">
        mdi-bell
      </v-icon>
    </v-badge>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Notifications
        </v-card-title>
        <v-card-text>
          <div :key="key" v-for="(value, key) in notifications">
            <p>{{ value.data.title }}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { GET_ALL_NOTIFICATIONS } from "@/core/services/store/general/notification.module";

export default {
  name: "Notifications",
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.$store.dispatch(GET_ALL_NOTIFICATIONS);
  },
  computed: {
    ...mapGetters(["notifications"]),
    count() {
      return this.notifications.length;
    },
  },
};
</script>

<style lang="scss">
.Notifications {
  display: flex;
  margin-right: 10px;
  align-items: center;

  .v-badge__badge {
    background: var(--v-error-base) !important;
  }
}
</style>
