var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{key:_vm.keyMenu,staticClass:"menu-nav"},[(
      (_vm.currentUser.roleId === '1' &&
        typeof this.campuses !== 'undefined' &&
        this.campuses.length > 0) ||
        _vm.currentUser.roleId !== '1'
    )?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.labelMain)+" ")]),(this.campuses && this.campuses.length > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.campus.name)+" "),_c('span',{staticClass:"icon-angle-down--margin-left"},[(_vm.campuses && _vm.campuses.length > 1)?_c('font-awesome-icon',{attrs:{"icon":"angle-down"}}):_vm._e()],1)])]):_vm._e(),(_vm.campuses && _vm.campuses.length > 1)?_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px"}},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('h3',{staticClass:"menu-heading menu-toggle"},[_vm._m(0),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("CAMPUS.CAMPUSES"))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('ul',{staticClass:"menu-inner menu-inner--scrollable"},_vm._l((_vm.campuses),function(campus){return _c('li',{key:campus.campusId,staticClass:"menu-item"},[_c('a',{staticClass:"menu-link",on:{"click":function($event){return _vm.changeCampus(campus)}}},[_vm._m(1,true),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(campus.name))])])])}),0)])])])]):_vm._e()]):_vm._e(),(
      _vm.currentUser.roleId === '1' && _vm.company && Object.keys(_vm.company).length > 0
    )?_c('li',[_c('v-btn',{staticClass:"ml-3 blue-button",attrs:{"color":"primary"},on:{"click":function($event){_vm.showMessageConfirmation = true}}},[_vm._v(_vm._s(_vm.$t("GENERAL.DISCONNECT")))])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.showMessageConfirmation),callback:function ($$v) {_vm.showMessageConfirmation=$$v},expression:"showMessageConfirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("MESSAGE.DISCONNECT.MODAL_TITLE"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.showMessageConfirmation = false}}},[_vm._v(" "+_vm._s(_vm.$t("MESSAGE.DELETE.MODAL_NO"))+" ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.disconnectOfCompany();
            _vm.showMessageConfirmation = false;}}},[_vm._v(" "+_vm._s(_vm.$t("MESSAGE.DELETE.MODAL_YES"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])
}]

export { render, staticRenderFns }