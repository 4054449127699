<template>
  <ul class="menu-nav" :key="keyMenu">
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
      v-if="
        (currentUser.roleId === '1' &&
          typeof this.campuses !== 'undefined' &&
          this.campuses.length > 0) ||
          currentUser.roleId !== '1'
      "
    >
      <span class="ml-3">
        {{ labelMain }}
      </span>
      <a
        href="#"
        class="menu-link menu-toggle"
        v-if="this.campuses && this.campuses.length > 0"
      >
        <span class="menu-text">
          {{ campus.name }}
          <span class="icon-angle-down--margin-left">
            <font-awesome-icon
              icon="angle-down"
              v-if="campuses && campuses.length > 1"
            />
          </span>
        </span>
      </a>
      <div
        class="menu-submenu menu-submenu-fixed"
        v-if="campuses && campuses.length > 1"
      >
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ $t("CAMPUS.CAMPUSES") }} </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner menu-inner--scrollable">
                <li
                  v-for="campus in campuses"
                  :key="campus.campusId"
                  class="menu-item"
                >
                  <a class="menu-link" @click="changeCampus(campus)">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">{{ campus.name }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li
      v-if="
        currentUser.roleId === '1' && company && Object.keys(company).length > 0
      "
    >
      <v-btn
        color="primary"
        class="ml-3 blue-button"
        v-on:click="showMessageConfirmation = true"
        >{{ $t("GENERAL.DISCONNECT") }}</v-btn
      >
    </li>
    <v-dialog v-model="showMessageConfirmation" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DISCONNECT.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showMessageConfirmation = false"
          >
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              disconnectOfCompany();
              showMessageConfirmation = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ul>
</template>

<script>
import { GET_ALL_PRODUCT_START_DATE_TEMPLATES } from "@/core/services/store/general/product_start_date_template.module";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "KTMenu",
  data() {
    return {
      keyIncremental: 1,
      showMessageConfirmation: false,
      keyMenu: 1,
    };
  },
  methods: {
    ...mapMutations(["setCampus", "setIsVisibleLoader"]),
    ...mapActions([
      "logoutCompany",
      "setCampusSelected",
      "getAllProductPricings",
    ]),
    /**
     * change campus
     * @method changeCampus
     */
    async changeCampus(campus) {
      this.setIsVisibleLoader(true);
      await this.setCampusSelected(campus.campusId);
      this.$store.dispatch(GET_ALL_PRODUCT_START_DATE_TEMPLATES, {
        campusId: campus.campusId,
      });
      await this.getAllProductPricings();
      if (this.$router.currentRoute.fullPath === "/home") {
        window.location.reload();
      } else {
        await this.$router.push("/home");
      }
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    disconnectOfCompany() {
      this.logoutCompany().then(() => {
        if (this.$router.currentRoute.fullPath === "/home") {
          window.location.reload();
        } else {
          this.$router.push("/home");
        }
      });
    },
  },
  computed: {
    ...mapGetters(["campuses", "campus", "currentUser", "company"]),
    labelMain() {
      const HOMESTAY = 3;
      const LODGING = 2;
      if (this.campus) {
        const { campusTypeId } = this.campus;
        if (this.campuses && this.campuses.length === 0) {
          return "";
        }

        return campusTypeId === HOMESTAY || campusTypeId === LODGING
          ? this.$t("GENERAL.MAIN_TITLE_2")
          : this.$t("GENERAL.MAIN_TITLE_1");
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.menu-inner--scrollable {
  overflow-x: scroll;
  max-height: calc(100vh - 200px);
}
.blue-button {
  background-color: #5867dd !important;
  border-color: #5867dd !important;
}
</style>
